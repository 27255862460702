export default {
    'problem_feedback.header_title': 'مسئلہ کی آراء',
    'problem_feedback.sub_title_1': 'صارف ID',
    'problem_feedback.sub_title_2': 'مسئلہ کی اقسام',
    'problem_feedback.sub_title_3': 'مسئلہ کی تفصیل',
    'problem_feedback.sub_title_4': 'مسئلہ کی تصویر',
    'problem_feedback.sub_title_5': 'فون نمبر (ضروری نہیں)',

    'problem_feedback.question_type_1': 'لاگ ان',
    'problem_feedback.question_type_2': 'ادائیگی',
    'problem_feedback.question_type_3': 'گیم',
    'problem_feedback.question_type_4': 'دیگر',

    'problem_feedback.sub_title_1_tips': 'براہ کرم مسئلہ کی قسم منتخب کریں',
    'problem_feedback.sub_title_2_tips': 'براہ کرم مسئلہ کی وضاحت فراہم کریں',
    'problem_feedback.sub_title_3_tips': 'براہ کرم اپنا سوال یہاں درج کریں!',
    'problem_feedback.sub_title_4_tips': 'اگر آپ کو ادائیگی میں مسئلہ ہو تو مسئلہ کو جلد حل کرنے کے لیے ادائیگی کا اسکرین شاٹ اپ لوڈ کریں',
    'problem_feedback.sub_title_5_tips': 'براہ کرم اپنا فون نمبر فراہم کریں تاکہ ہم اہم مسائل کے لیے جلدی سے آپ سے رابطہ کر سکیں',

    'problem_feedback.popup_title': 'نوٹس',
    'problem_feedback.popup_content': 'آپ کی آراء کا شکریہ! ہم جتنی جلدی ممکن ہو سکے آپ کے مسائل کو حل کریں گے۔ اگر آپ کی آراء موثر ثابت ہوئیں تو آپ کو سونے کے سکوں کے انعامات مل سکتے ہیں۔ براہ کرم اپنے ای میل کو اپ ڈیٹس کے لیے چیک کریں',
    'problem_feedback.determine': 'تصدیق شدہ',

    'problem_feedback.uploading': 'اپ لوڈ ہو رہا ہے',
    'problem_feedback.failed': 'ناکام',
    'problem_feedback.least': 'براہ کرم کم از کم ایک تصویر اپ لوڈ کریں',
    'problem_feedback.not_supported': 'غیر معاون تصویر',
};
