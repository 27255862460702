export default {
    'problem_feedback.header_title': '문제 피드백',
    'problem_feedback.sub_title_1': '사용자 ID',
    'problem_feedback.sub_title_2': '문제 유형',
    'problem_feedback.sub_title_3': '문제 상세',
    'problem_feedback.sub_title_4': '문제 사진',
    'problem_feedback.sub_title_5': '전화번호 (필수 아님)',

    'problem_feedback.question_type_1': '로그인',
    'problem_feedback.question_type_2': '결제',
    'problem_feedback.question_type_3': '게임',
    'problem_feedback.question_type_4': '기타',

    'problem_feedback.sub_title_1_tips': '문제 유형을 선택해 주세요',
    'problem_feedback.sub_title_2_tips': '문제에 대한 설명을 제공해 주세요',
    'problem_feedback.sub_title_3_tips': '여기에 질문을 입력해 주세요!',
    'problem_feedback.sub_title_4_tips': '결제 문제가 발생한 경우, 문제 해결을 위해 결제 스크린샷을 업로드해 주세요',
    'problem_feedback.sub_title_5_tips': '중요한 문제에 대해 빠르게 연락할 수 있도록 전화번호를 제공해 주세요',

    'problem_feedback.popup_title': '알림',
    'problem_feedback.popup_content': '피드백 감사합니다! 저희는 문제가 발생한 사항을 최대한 빨리 해결할 것입니다. 피드백이 유효하다면 금화 보상을 받을 수 있습니다. 이메일을 확인해 주세요',
    'problem_feedback.determine': '확인',

    'problem_feedback.uploading': '업로드 중',
    'problem_feedback.failed': '실패',
    'problem_feedback.least': '최소한 한 장의 이미지를 업로드해 주세요',
    'problem_feedback.not_supported': '지원되지 않는 이미지',
};
