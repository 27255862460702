import { ref } from "@vue/reactivity";
import { getJsUrl } from '@/utils/function';
import { createI18n } from 'vue-i18n';
import cn from './cn';  // 中文
import en from './en';  // 英文
import vn from './vn';  // 越南语
import pt from './pt';  // 葡萄牙语
import id from './id';  // 印尼语
import th from './th';  // 泰语
import my from './my';  // 马来语
import es from './es';  // 墨西哥语
import kor from './kor';  // 韩语
import pk from './pk';  // 巴基斯坦语
import zh_tc from './zh_tc';  // 繁体中文

var route:any;

if(location.href.indexOf('?') !== -1){
    route = getJsUrl(location.href);
}

if(route && route.language){
    sessionStorage.setItem('locale-language',route.language);
}else{
    const language = sessionStorage.getItem('locale-language');
    language ? sessionStorage.setItem('locale-language',language) : sessionStorage.setItem('locale-language','en');
}



const defaultLocale = ref(sessionStorage.getItem('locale-language') ? sessionStorage.getItem('locale-language') : route.language ? route.language : 'en');

const i18n = createI18n({
    locale: defaultLocale.value,
    fallbackLocale: 'en',
    allowComposition: true,
    messages: {
        // cn,
        en,
        vn,
        pt,
        id,
        th,
        my,
        es,
        kor,
        pk,
        // zh_tc
    }
  });
  
  export {
    defaultLocale,
    i18n
  };