export default {
    'problem_feedback.header_title': 'Maklum Balas Masalah',
    'problem_feedback.sub_title_1': 'ID Pengguna',
    'problem_feedback.sub_title_2': 'Jenis Masalah',
    'problem_feedback.sub_title_3': 'Butiran Masalah',
    'problem_feedback.sub_title_4': 'Gambar Masalah',
    'problem_feedback.sub_title_5': 'Nombor Telefon (Tidak Wajib)',

    'problem_feedback.question_type_1': 'Log Masuk',
    'problem_feedback.question_type_2': 'Pembayaran',
    'problem_feedback.question_type_3': 'Permainan',
    'problem_feedback.question_type_4': 'Lain-lain',

    'problem_feedback.sub_title_1_tips': 'Sila pilih jenis masalah',
    'problem_feedback.sub_title_2_tips': 'Sila beri penerangan tentang masalah tersebut',
    'problem_feedback.sub_title_3_tips': 'Sila masukkan soalan anda di sini!',
    'problem_feedback.sub_title_4_tips': 'Sekiranya anda menghadapi masalah pembayaran, sila muat naik tangkapan skrin pembayaran untuk menyelesaikan masalah dengan lebih cepat',
    'problem_feedback.sub_title_5_tips': 'Sila berikan nombor telefon anda supaya kami boleh menghubungi anda dengan segera untuk masalah penting',

    'problem_feedback.popup_title': 'MAKLUMAN',
    'problem_feedback.popup_content': 'Terima kasih atas maklum balas anda! Kami akan menangani masalah yang anda hadapi secepat mungkin. Jika maklum balas anda berkesan, anda mungkin akan menerima ganjaran sejumlah syiling emas. Sila semak e-mel anda untuk kemas kini',
    'problem_feedback.determine': 'Disahkan',

    'problem_feedback.uploading': 'Memuat naik',
    'problem_feedback.failed': 'Gagal',
    'problem_feedback.least': 'Sila muat naik sekurang-kurangnya satu imej',
    'problem_feedback.not_supported': 'Imej tidak disokong',
};
