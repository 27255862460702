export default {
    'problem_feedback.header_title': 'Comentarios de Problemas',
    'problem_feedback.sub_title_1': 'ID de Usuario',
    'problem_feedback.sub_title_2': 'Tipos de Problema',
    'problem_feedback.sub_title_3': 'Detalles del Problema',
    'problem_feedback.sub_title_4': 'Imagen del Problema',
    'problem_feedback.sub_title_5': 'Número de Teléfono (No Obligatorio)',

    'problem_feedback.question_type_1': 'Inicio de Sesión',
    'problem_feedback.question_type_2': 'Pago',
    'problem_feedback.question_type_3': 'Juego',
    'problem_feedback.question_type_4': 'Otro',

    'problem_feedback.sub_title_1_tips': 'Por favor, seleccione el tipo de problema',
    'problem_feedback.sub_title_2_tips': 'Por favor, proporcione una descripción del problema',
    'problem_feedback.sub_title_3_tips': '¡Por favor, ingrese su pregunta aquí!',
    'problem_feedback.sub_title_4_tips': 'Si está experimentando problemas con el pago, cargue una captura de pantalla del pago para resolver el problema más rápidamente',
    'problem_feedback.sub_title_5_tips': 'Por favor, proporcione su número de teléfono para que podamos contactarlo rápidamente para problemas importantes',

    'problem_feedback.popup_title': 'AVISO',
    'problem_feedback.popup_content': '¡Gracias por su comentario! Abordaremos los problemas que encontró lo antes posible. Si su comentario es efectivo, puede recibir una recompensa en monedas de oro. Por favor, revise su correo electrónico para obtener actualizaciones',
    'problem_feedback.determine': 'Confirmado',

    'problem_feedback.uploading': 'Subiendo',
    'problem_feedback.failed': 'Fallido',
    'problem_feedback.least': 'Por favor, suba al menos una imagen',
    'problem_feedback.not_supported': 'Imagen no soportada',
};
